@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap);
#page-topbar{
    position:fixed;
    top:0;
    right:0;
    left:0;
    z-index:1002;
    background-color:#fff;
}
.navbar-header{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:justify;
    -webkit-box-pack:justify;
    justify-content:space-between;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    margin:0 auto;
    height:70px;
    padding:0 calc(24px / 2) 0 0
}
.navbar-header .dropdown .show.header-item{
    background-color:#f8f9fa
}
.navbar-brand-box{
    padding:0 1.5rem;
    text-align:center;
    width:250px
}
.logo{
    line-height:70px
}
.logo .logo-sm{
    display:none
}
.logo-light{
    display:none
}
.app-search{
    padding:calc(32px / 2) 0
}
.app-search .form-control{
    border:none;
    height:38px;
    padding-left:40px;
    padding-right:20px;
    background-color:#f3f3f9;
    -webkit-box-shadow:none;
    box-shadow:none;
    border-radius:30px
}
.app-search span{
    position:absolute;
    z-index:10;
    font-size:16px;
    line-height:38px;
    left:13px;
    top:0;
    color:#74788d
}
.megamenu-list li{
    position:relative;
    padding:5px 0
}
.megamenu-list li a{
    color:#495057
}
@media (max-width:992px){
    .navbar-brand-box{
        width:auto
    }
    .logo span.logo-lg{
        display:none
    }
    .logo span.logo-sm{
        display:inline-block
    }
}
.page-content{
    padding:calc(70px + 24px) calc(24px / 2) 60px calc(24px / 2)
}
.header-item{
    height:70px;
    -webkit-box-shadow:none!important;
    box-shadow:none!important;
    color:#555b6d;
    border:0;
    border-radius:0
}
.header-item:hover{
    color:#555b6d
}
.header-profile-user{
    height:36px;
    width:36px;
    background-color:#f6f6f6;
    padding:3px
}
.noti-icon i{
    font-size:22px;
    color:#555b6d
}
.noti-icon .badge{
    position:absolute;
    top:12px;
    right:4px
}
.notification-item .d-flex{
    padding:.75rem 1rem
}
.notification-item .d-flex:hover{
    background-color:#f6f6f6
}
.dropdown-icon-item{
    display:block;
    border-radius:3px;
    line-height:34px;
    text-align:center;
    padding:15px 0 9px;
    display:block;
    border:1px solid transparent;
    color:#74788d
}
.dropdown-icon-item img{
    height:24px
}
.dropdown-icon-item span{
    display:block;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
}
.dropdown-icon-item:hover{
    border-color:#eff2f7
}
.fullscreen-enable [data-bs-toggle=fullscreen] .bx-fullscreen::before{
    content:"\ea3f"
}

body .navbar-header .dropdown .show.header-item{
    background-color:rgba(255,255,255,.05)
}
body .navbar-header .waves-effect .waves-ripple{
    background:rgba(255,255,255,.4)
}
body .header-item{
    color:#e9ecef
}
body .header-item:hover{
    color:#e9ecef
}
body .header-profile-user{
    background-color:rgba(255,255,255,.25)
}
body .noti-icon i{
    color:#e9ecef
}
body .logo-dark{
    display:none
}
body .logo-light{
    display:block
}
body .app-search .form-control{
    background-color:rgba(243,243,249,.07);
    color:#fff
}
body .app-search input.form-control::-webkit-input-placeholder,body .app-search span{
    color:rgba(255,255,255,.5)
}
body[data-sidebar=dark] .navbar-brand-box{
    background:#2a3042
}
body[data-sidebar=dark] .logo-dark{
    display:none
}
body[data-sidebar=dark] .logo-light{
    display:block
}
@media (max-width:600px){
    .navbar-header .dropdown{
        position:static
    }
    .navbar-header .dropdown .dropdown-menu{
        left:10px!important;
        right:10px!important
    }
}
@media (max-width:380px){
    .navbar-brand-box{
        display:none
    }
}
body[data-layout=horizontal] .navbar-brand-box{
    width:auto
}
body[data-layout=horizontal] .page-content{
    margin-top:70px;
    padding:calc(55px + 24px) calc(24px / 2) 60px calc(24px / 2)
}
@media (max-width:992px){
    body[data-layout=horizontal] .page-content{
        margin-top:15px
    }
}
.page-title-box{
    padding-bottom:24px
}
.page-title-box .breadcrumb{
    background-color:transparent;
    padding:0
}
.page-title-box h4{
    text-transform:uppercase;
    font-weight:600;
    font-size:16px!important
}
.footer{
    bottom:0;
    padding:20px calc(24px / 2);
    position:absolute;
    right:0;
    color:#74788d;
    left:250px;
    height:60px;
    background-color:#f2f2f5
}
@media (max-width:991.98px){
    .footer{
        left:0
    }
}
.vertical-collpsed .footer{
    left:70px
}
@media (max-width:991.98px){
    .vertical-collpsed .footer{
        left:0
    }
}
body[data-layout=horizontal] .footer{
    left:0!important
}
.right-bar{
    background-color:#fff;
    -webkit-box-shadow:0 0 24px 0 rgba(0,0,0,.06),0 1px 0 0 rgba(0,0,0,.02);
    box-shadow:0 0 24px 0 rgba(0,0,0,.06),0 1px 0 0 rgba(0,0,0,.02);
    display:block;
    position:fixed;
    -webkit-transition:all .2s ease-out;
    transition:all .2s ease-out;
    width:280px;
    z-index:9999;
    float:right!important;
    right:-290px;
    top:0;
    bottom:0
}
.right-bar .right-bar-toggle{
    background-color:#444c54;
    height:24px;
    width:24px;
    line-height:24px;
    display:block;
    color:#eff2f7;
    text-align:center;
    border-radius:50%
}
.right-bar .right-bar-toggle:hover{
    background-color:#4b545c
}
.rightbar-overlay{
    background-color:rgba(52,58,64,.55);
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    display:none;
    z-index:9998;
    -webkit-transition:all .2s ease-out;
    transition:all .2s ease-out
}
.right-bar-enabled .right-bar{
    right:0
}
.right-bar-enabled .rightbar-overlay{
    display:block
}
@media (max-width:767.98px){
    .right-bar{
        overflow:auto
    }
    .right-bar .slimscroll-menu{
        height:auto!important
    }
}
.metismenu{
    margin:0;
    width:100%;
    padding-left:0rem;
}
.metismenu li{
    display:block;
    width:100%
}
.metismenu .mm-collapse{
    display:none
}
.metismenu .mm-collapse:not(.mm-show){
    display:none
}
.metismenu .mm-collapse.mm-show{
    display:block
}
.metismenu .mm-collapsing{
    position:relative;
    height:0;
    overflow:hidden;
    -webkit-transition-timing-function:ease;
    transition-timing-function:ease;
    -webkit-transition-duration:.35s;
    transition-duration:.35s;
    -webkit-transition-property:height,visibility;
    transition-property:height,visibility
}
.vertical-menu{
    width:250px;
    z-index:1001;
    background:#fff;
    bottom:0;
    margin-top:0;
    position:fixed;
    top:70px;
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03)
}
.main-content{
    margin-left:250px;
     /*overflow: hidden;*/ 
}
.main-content .content{
    padding:0 15px 10px 15px;
    margin-top:70px
}
#sidebar-menu{
    padding:10px 0 30px 0
}
#sidebar-menu .mm-active>.has-arrow:after{
    -webkit-transform:rotate(-180deg);
    transform:rotate(-180deg)
}
#sidebar-menu .has-arrow:after{
    content:"\F0140";
    font-family:'Material Design Icons';
    display:block;
    float:right;
    -webkit-transition:-webkit-transform .2s;
    transition:-webkit-transform .2s;
    transition:transform .2s;
    transition:transform .2s,-webkit-transform .2s;
    font-size:1rem
}
#sidebar-menu ul li a{
    display:block;
    padding:.625rem 1.5rem;
    color:#545a6d;
    position:relative;
    font-size:13px;
    -webkit-transition:all .4s;
    transition:all .4s
}
#sidebar-menu ul li a i{
    display:inline-block;
    min-width:1.75rem;
    padding-bottom:.125em;
    font-size:1.25rem;
    line-height:1.40625rem;
    vertical-align:middle;
    color:#7f8387;
    -webkit-transition:all .4s;
    transition:all .4s
}
#sidebar-menu ul li a:hover{
    color:#383c40
}
#sidebar-menu ul li a:hover i{
    color:#383c40
}
#sidebar-menu ul li .badge{
    margin-top:4px
}
#sidebar-menu ul li ul.sub-menu{
    padding:0
}
#sidebar-menu ul li ul.sub-menu li a{
    padding:.4rem 1.5rem .4rem 3.5rem;
    font-size:13px;
    color:#545a6d
}
#sidebar-menu ul li ul.sub-menu li a:hover{
    color:#383c40
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu{
    padding:0
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a{
    padding:.4rem 1.5rem .4rem 4.5rem;
    font-size:13px
}
.menu-title{
    padding:12px 20px!important;
    letter-spacing:.05em;
    pointer-events:none;
    cursor:default;
    font-size:11px;
    text-transform:uppercase;
    color:#7f8387;
    font-weight:600
}
.mm-active{
    color:#556ee6!important
}
.mm-active>a{
    color:#556ee6!important
}
.mm-active>a i{
    color:#556ee6!important
}
.mm-active .active{
    color:#556ee6!important
}
.mm-active .active i{
    color:#556ee6!important
}
.mm-active>i{
    color:#556ee6!important
}
@media (max-width:992px){
    .vertical-menu{
        display:none
    }
    .main-content{
        margin-left:0!important
    }
    body.sidebar-enable .vertical-menu{
        display:block
    }
}
.vertical-collpsed .main-content{
    margin-left:70px
}
.vertical-collpsed .navbar-brand-box{
    width:70px!important
}
.vertical-collpsed .logo span.logo-lg{
    display:none
}
.vertical-collpsed .logo span.logo-sm{
    display:block
}
.vertical-collpsed .vertical-menu{
    position:absolute;
    width:70px!important;
    z-index:5
}
.vertical-collpsed .vertical-menu .simplebar-content-wrapper,.vertical-collpsed .vertical-menu .simplebar-mask{
    overflow:visible!important
}
.vertical-collpsed .vertical-menu .simplebar-scrollbar{
    display:none!important
}
.vertical-collpsed .vertical-menu .simplebar-offset{
    bottom:0!important
}
.vertical-collpsed .vertical-menu #sidebar-menu .badge,.vertical-collpsed .vertical-menu #sidebar-menu .collapse.in,.vertical-collpsed .vertical-menu #sidebar-menu .menu-title{
    display:none!important
}
.vertical-collpsed .vertical-menu #sidebar-menu .nav.collapse{
    height:inherit!important
}
.vertical-collpsed .vertical-menu #sidebar-menu .has-arrow:after{
    display:none
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li{
    position:relative;
    white-space:nowrap
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a{
    padding:15px 20px;
    min-height:55px;
    -webkit-transition:none;
    transition:none
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:active,.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:focus,.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:hover{
    color:#383c40
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i{
    font-size:1.45rem;
    margin-left:4px
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span{
    display:none;
    padding-left:25px
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a{
    position:relative;
    width:calc(190px + 70px);
    color:#556ee6;
    background-color:#f5f5f5;
    -webkit-transition:none;
    transition:none
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i{
    color:#556ee6
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a span{
    display:inline
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul{
    display:block;
    left:70px;
    position:absolute;
    width:190px;
    height:auto!important;
    -webkit-box-shadow:3px 5px 10px 0 rgba(54,61,71,.1);
    box-shadow:3px 5px 10px 0 rgba(54,61,71,.1)
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul ul{
    -webkit-box-shadow:3px 5px 10px 0 rgba(54,61,71,.1);
    box-shadow:3px 5px 10px 0 rgba(54,61,71,.1)
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a{
    -webkit-box-shadow:none;
    box-shadow:none;
    padding:8px 20px;
    position:relative;
    width:190px;
    z-index:6;
    color:#545a6d
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a:hover{
    color:#383c40
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul ul{
    padding:5px 0;
    z-index:9999;
    display:none;
    background-color:#fff
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li:hover>ul{
    display:block;
    left:190px;
    height:auto!important;
    margin-top:-36px;
    position:absolute;
    width:190px
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li>a span.pull-right{
    position:absolute;
    right:20px;
    top:12px;
    -webkit-transform:rotate(270deg);
    transform:rotate(270deg)
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li.active a{
    color:#f8f9fa
}
body[data-sidebar=dark] .vertical-menu{
    background:#2a3042
}
body[data-sidebar=dark] #sidebar-menu ul li a{
    color:#a6b0cf
}
body[data-sidebar=dark] #sidebar-menu ul li a i{
    color:#6a7187
}
body[data-sidebar=dark] #sidebar-menu ul li a:hover{
    color:#fff
}
body[data-sidebar=dark] #sidebar-menu ul li a:hover i{
    color:#fff
}
body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a{
    color:#79829c
}
body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:hover{
    color:#fff
}
body[data-sidebar=dark].vertical-collpsed{
    min-height:1760px
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a{
    background:#2e3548;
    color:#fff
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i{
    color:#fff
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a{
    color:#79829c
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a:hover{
    color:#fff
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu>ul ul{
    background-color:#2a3042
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active{
    color:#fff!important
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active i{
    color:#fff!important
}
body[data-sidebar=dark] .mm-active{
    color:#fff!important
}
body[data-sidebar=dark] .mm-active>a{
    color:#fff!important
}
body[data-sidebar=dark] .mm-active>a i{
    color:#fff!important
}
body[data-sidebar=dark] .mm-active>i{
    color:#fff!important
}
body[data-sidebar=dark] .mm-active .active{
    color:#fff!important
}
body[data-sidebar=dark] .mm-active .active i{
    color:#fff!important
}
body[data-sidebar=dark] .menu-title{
    color:#6a7187
}
body[data-layout=horizontal] .main-content{
    margin-left:0!important
}
body[data-sidebar-size=small] .navbar-brand-box{
    width:160px
}
body[data-sidebar-size=small] .vertical-menu{
    width:160px;
    text-align:center
}
body[data-sidebar-size=small] .vertical-menu .badge,body[data-sidebar-size=small] .vertical-menu .has-arrow:after{
    display:none!important
}
body[data-sidebar-size=small] .main-content{
    margin-left:160px
}
body[data-sidebar-size=small] .footer{
    left:160px
}
@media (max-width:991.98px){
    body[data-sidebar-size=small] .footer{
        left:0
    }
}
body[data-sidebar-size=small] #sidebar-menu ul li.menu-title{
    background-color:#2e3548
}
body[data-sidebar-size=small] #sidebar-menu ul li a i{
    display:block
}
body[data-sidebar-size=small] #sidebar-menu ul li ul.sub-menu li a{
    padding-left:1.5rem
}
body[data-sidebar-size=small] #sidebar-menu ul li ul.sub-menu li ul.sub-menu li a{
    padding-left:1.5rem
}
body[data-sidebar-size=small].vertical-collpsed .main-content{
    margin-left:70px
}
body[data-sidebar-size=small].vertical-collpsed .vertical-menu #sidebar-menu{
    text-align:left
}
body[data-sidebar-size=small].vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i{
    display:inline-block
}
body[data-sidebar-size=small].vertical-collpsed .footer{
    left:70px
}
body[data-sidebar=colored] .vertical-menu{
    background-color:#556ee6
}
body[data-sidebar=colored] .navbar-brand-box{
    background-color:#556ee6
}
body[data-sidebar=colored] .navbar-brand-box .logo-dark{
    display:none
}
body[data-sidebar=colored] .navbar-brand-box .logo-light{
    display:block
}
body[data-sidebar=colored] .mm-active{
    color:#fff!important
}
body[data-sidebar=colored] .mm-active>a{
    color:#fff!important
}
body[data-sidebar=colored] .mm-active>a i{
    color:#fff!important
}
body[data-sidebar=colored] .mm-active .active,body[data-sidebar=colored] .mm-active>i{
    color:#fff!important
}
body[data-sidebar=colored] #sidebar-menu ul li.menu-title{
    color:rgba(255,255,255,.6)
}
body[data-sidebar=colored] #sidebar-menu ul li a{
    color:rgba(255,255,255,.6)
}
body[data-sidebar=colored] #sidebar-menu ul li a i{
    color:rgba(255,255,255,.6)
}
body[data-sidebar=colored] #sidebar-menu ul li a.waves-effect .waves-ripple{
    background:rgba(255,255,255,.1)
}
body[data-sidebar=colored] #sidebar-menu ul li a:hover{
    color:#fff
}
body[data-sidebar=colored] #sidebar-menu ul li a:hover i{
    color:#fff
}
body[data-sidebar=colored] #sidebar-menu ul li ul.sub-menu li a{
    color:rgba(255,255,255,.5)
}
body[data-sidebar=colored] #sidebar-menu ul li ul.sub-menu li a:hover{
    color:#fff
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a{
    background-color:#5e76e7;
    color:#fff
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i{
    color:#fff
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active{
    color:#556ee6!important
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu ul li ul.sub-menu li a:hover{
    color:#556ee6
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu ul li ul.sub-menu li.mm-active{
    color:#556ee6!important
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu ul li ul.sub-menu li.mm-active>a{
    color:#556ee6!important
}
body[data-sidebar=colored].vertical-collpsed .vertical-menu #sidebar-menu ul li ul.sub-menu li.mm-active>a i{
    color:#556ee6!important
}
.topnav{
    background:#fff;
    padding:0 calc(24px / 2);
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    margin-top:70px;
    position:fixed;
    left:0;
    right:0;
    z-index:100
}
.topnav .topnav-menu{
    margin:0;
    padding:0
}
.topnav .navbar-nav .nav-link{
    font-size:14px;
    position:relative;
    padding:1rem 1.3rem;
    color:#545a6d
}
.topnav .navbar-nav .nav-link i{
    font-size:15px
}
.topnav .navbar-nav .nav-link:focus,.topnav .navbar-nav .nav-link:hover{
    color:#556ee6;
    background-color:transparent
}
.topnav .navbar-nav .dropdown-item{
    color:#545a6d
}
.topnav .navbar-nav .dropdown-item.active,.topnav .navbar-nav .dropdown-item:hover{
    color:#556ee6
}
.topnav .navbar-nav .nav-item .nav-link.active{
    color:#556ee6
}
.topnav .navbar-nav .dropdown.active>a{
    color:#556ee6;
    background-color:transparent
}
@media (min-width:1200px){
    body[data-layout=horizontal] .container-fluid,body[data-layout=horizontal] .navbar-header{
        max-width:85%
    }
}
@media (min-width:992px){
    .topnav .navbar-nav .nav-item:first-of-type .nav-link{
        padding-left:0
    }
    .topnav .dropdown-item{
        padding:.5rem 1.5rem;
        min-width:180px
    }
    .topnav .dropdown.mega-dropdown .mega-dropdown-menu{
        left:0;
        right:auto
    }
    .topnav .dropdown .dropdown-menu{
        margin-top:0;
        border-radius:0 0 .25rem .25rem
    }
    .topnav .dropdown .dropdown-menu .arrow-down::after{
        right:15px;
        -webkit-transform:rotate(-135deg) translateY(-50%);
        transform:rotate(-135deg) translateY(-50%);
        position:absolute
    }
    .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu{
        position:absolute;
        top:0!important;
        left:100%;
        display:none
    }
    .topnav .dropdown:hover>.dropdown-menu{
        display:block
    }
    .topnav .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu{
        display:block
    }
    .navbar-toggle{
        display:none
    }
}
.arrow-down{
    display:inline-block
}
.arrow-down:after{
    border-color:initial;
    border-style:solid;
    border-width:0 0 1px 1px;
    content:"";
    height:.4em;
    display:inline-block;
    right:5px;
    top:50%;
    margin-left:10px;
    -webkit-transform:rotate(-45deg) translateY(-50%);
    transform:rotate(-45deg) translateY(-50%);
    -webkit-transform-origin:top;
    transform-origin:top;
    -webkit-transition:all .3s ease-out;
    transition:all .3s ease-out;
    width:.4em
}
@media (max-width:1199.98px){
    .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu{
        right:100%;
        left:auto
    }
}
@media (max-width:991.98px){
    .navbar-brand-box .logo-dark{
        display:block
    }
    .navbar-brand-box .logo-dark span.logo-sm{
        display:block
    }
    .navbar-brand-box .logo-light{
        display:none
    }
    .topnav{
        max-height:360px;
        overflow-y:auto;
        padding:0
    }
    .topnav .navbar-nav .nav-link{
        padding:.75rem 1.1rem
    }
    .topnav .dropdown .dropdown-menu{
        background-color:transparent;
        border:none;
        -webkit-box-shadow:none;
        box-shadow:none;
        padding-left:15px
    }
    .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl{
        width:auto
    }
    .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl .row{
        margin:0
    }
    .topnav .dropdown .dropdown-item{
        position:relative;
        background-color:transparent
    }
    .topnav .dropdown .dropdown-item.active,.topnav .dropdown .dropdown-item:active{
        color:#556ee6
    }
    .topnav .arrow-down::after{
        right:15px;
        position:absolute
    }
}
@media (min-width:992px){
    body[data-layout=horizontal][data-topbar=light] .navbar-brand-box .logo-dark{
        display:block
    }
    body[data-layout=horizontal][data-topbar=light] .navbar-brand-box .logo-light{
        display:none
    }
    body[data-layout=horizontal][data-topbar=light] .topnav{
        background-color:#556ee6
    }
    body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link{
        color:rgba(255,255,255,.6)
    }
    body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link:focus,body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav .nav-link:hover{
        color:rgba(255,255,255,.9)
    }
    body[data-layout=horizontal][data-topbar=light] .topnav .navbar-nav>.dropdown.active>a{
        color:rgba(255,255,255,.9)!important
    }
}
body[data-layout=horizontal][data-topbar=colored] #page-topbar{
    background-color:#556ee6;
    -webkit-box-shadow:none;
    box-shadow:none
}
body[data-layout=horizontal][data-topbar=colored] .logo-dark{
    display:none
}
body[data-layout=horizontal][data-topbar=colored] .logo-light{
    display:block
}
body[data-layout=horizontal][data-topbar=colored] .app-search .form-control{
    background-color:rgba(243,243,249,.07);
    color:#fff
}
body[data-layout=horizontal][data-topbar=colored] .app-search input.form-control::-webkit-input-placeholder,body[data-layout=horizontal][data-topbar=colored] .app-search span{
    color:rgba(255,255,255,.5)
}
body[data-layout=horizontal][data-topbar=colored] .header-item{
    color:#e9ecef
}
body[data-layout=horizontal][data-topbar=colored] .header-item:hover{
    color:#e9ecef
}
body[data-layout=horizontal][data-topbar=colored] .navbar-header .dropdown .show.header-item{
    background-color:rgba(255,255,255,.1)
}
body[data-layout=horizontal][data-topbar=colored] .navbar-header .waves-effect .waves-ripple{
    background:rgba(255,255,255,.4)
}
body[data-layout=horizontal][data-topbar=colored] .noti-icon i{
    color:#e9ecef
}
@media (min-width:992px){
    body[data-layout=horizontal][data-topbar=colored] .topnav{
        background-color:#556ee6
    }
    body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link{
        color:rgba(255,255,255,.6)
    }
    body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:focus,body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:hover{
        color:rgba(255,255,255,.9)
    }
    body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav>.dropdown.active>a{
        color:rgba(255,255,255,.9)!important
    }
}
body[data-layout-size=boxed]{
    background-color:#ebebf4
}
body[data-layout-size=boxed] #layout-wrapper{
    background-color:#f8f8fb;
    max-width:1300px;
    margin:0 auto;
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03)
}
body[data-layout-size=boxed] #page-topbar{
    max-width:1300px;
    margin:0 auto
}
body[data-layout-size=boxed] .footer{
    margin:0 auto;
    max-width:calc(1300px - 250px)
}
body[data-layout-size=boxed].vertical-collpsed .footer{
    max-width:calc(1300px - 70px)
}
body[data-layout=horizontal][data-layout-size=boxed] #layout-wrapper,body[data-layout=horizontal][data-layout-size=boxed] #page-topbar,body[data-layout=horizontal][data-layout-size=boxed] .footer{
    max-width:100%
}
body[data-layout=horizontal][data-layout-size=boxed] .container-fluid,body[data-layout=horizontal][data-layout-size=boxed] .navbar-header{
    max-width:1300px
}
@media (min-width:992px){
    body[data-layout-scrollable=true] #page-topbar,body[data-layout-scrollable=true] .vertical-menu{
        position:absolute
    }
}
@media (min-width:992px){
    body[data-layout-scrollable=true][data-layout=horizontal] #page-topbar,body[data-layout-scrollable=true][data-layout=horizontal] .topnav{
        position:absolute
    }
}

.avatar-xs{
    height:2rem;
    width:2rem
}
.avatar-sm{
    height:3rem;
    width:3rem
}
.avatar-md{
    height:4.5rem;
    width:4.5rem
}
.avatar-lg{
    height:6rem;
    width:6rem
}
.avatar-xl{
    height:7.5rem;
    width:7.5rem
}
.avatar-title{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    background-color:#556ee6;
    color:#fff;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    font-weight:500;
    height:100%;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    width:100%
}
.avatar-group{
    padding-left:12px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap
}
.avatar-group .avatar-group-item{
    margin-left:-12px;
    border:2px solid #fff;
    border-radius:50%;
    -webkit-transition:all .2s;
    transition:all .2s
}
.avatar-group .avatar-group-item:hover{
    position:relative;
    -webkit-transform:translateY(-2px);
    transform:translateY(-2px)
}
.custom-accordion .accordion-list{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    border-radius:7px;
    background-color:#f6f6f6;
    padding:12px 20px;
    color:#495057;
    font-weight:600;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.custom-accordion .accordion-list.collapsed i.accor-plus-icon:before{
    content:"\F0415"
}
.custom-accordion .accordion-list .accor-plus-icon{
    display:inline-block;
    font-size:16px;
    height:24px;
    width:24px;
    line-height:22px;
    background-color:#fff;
    text-align:center;
    border-radius:50%
}
.custom-accordion a.collapsed i.accor-down-icon:before{
    content:"\F0140"
}
.custom-accordion .card-body{
    color:#74788d
}
.font-size-10{
    font-size:10px!important
}
.font-size-11{
    font-size:11px!important
}
.font-size-12{
    font-size:12px!important
}
.font-size-13{
    font-size:13px!important
}
.font-size-14{
    font-size:14px!important
}
.font-size-15{
    font-size:15px!important
}
.font-size-16{
    font-size:16px!important
}
.font-size-17{
    font-size:17px!important
}
.font-size-18{
    font-size:18px!important
}
.font-size-20{
    font-size:20px!important
}
.font-size-22{
    font-size:22px!important
}
.font-size-24{
    font-size:24px!important
}
.fw-medium{
    font-weight:500
}
.fw-semibold{
    font-weight:600
}
.social-list-item{
    height:2rem;
    width:2rem;
    line-height:calc(2rem - 4px);
    display:block;
    border:2px solid #adb5bd;
    border-radius:50%;
    color:#adb5bd;
    text-align:center;
    -webkit-transition:all .4s;
    transition:all .4s
}
.social-list-item:hover{
    color:#74788d;
    background-color:#eff2f7
}
.w-xs{
    min-width:80px
}
.w-sm{
    min-width:95px
}
.w-md{
    min-width:110px
}
.w-lg{
    min-width:140px
}
.w-xl{
    min-width:160px
}
.alert-dismissible .btn-close{
    font-size:10px;
    padding:1.05rem 1.25rem;
    background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
}
#preloader{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:#fff;
    z-index:9999
}
#status{
    width:40px;
    height:40px;
    position:absolute;
    left:50%;
    top:50%;
    margin:-20px 0 0 -20px
}
.spinner-chase{
    margin:0 auto;
    width:40px;
    height:40px;
    position:relative;
    -webkit-animation:spinner-chase 2.5s infinite linear both;
    animation:spinner-chase 2.5s infinite linear both
}
.chase-dot{
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    -webkit-animation:chase-dot 2s infinite ease-in-out both;
    animation:chase-dot 2s infinite ease-in-out both
}
.chase-dot:before{
    content:'';
    display:block;
    width:25%;
    height:25%;
    background-color:#556ee6;
    border-radius:100%;
    -webkit-animation:chase-dot-before 2s infinite ease-in-out both;
    animation:chase-dot-before 2s infinite ease-in-out both
}
.chase-dot:nth-child(1){
    -webkit-animation-delay:-1.1s;
    animation-delay:-1.1s
}
.chase-dot:nth-child(1):before{
    -webkit-animation-delay:-1.1s;
    animation-delay:-1.1s
}
.chase-dot:nth-child(2){
    -webkit-animation-delay:-1s;
    animation-delay:-1s
}
.chase-dot:nth-child(2):before{
    -webkit-animation-delay:-1s;
    animation-delay:-1s
}
.chase-dot:nth-child(3){
    -webkit-animation-delay:-.9s;
    animation-delay:-.9s
}
.chase-dot:nth-child(3):before{
    -webkit-animation-delay:-.9s;
    animation-delay:-.9s
}
.chase-dot:nth-child(4){
    -webkit-animation-delay:-.8s;
    animation-delay:-.8s
}
.chase-dot:nth-child(4):before{
    -webkit-animation-delay:-.8s;
    animation-delay:-.8s
}
.chase-dot:nth-child(5){
    -webkit-animation-delay:-.7s;
    animation-delay:-.7s
}
.chase-dot:nth-child(5):before{
    -webkit-animation-delay:-.7s;
    animation-delay:-.7s
}
.chase-dot:nth-child(6){
    -webkit-animation-delay:-.6s;
    animation-delay:-.6s
}
.chase-dot:nth-child(6):before{
    -webkit-animation-delay:-.6s;
    animation-delay:-.6s
}
@-webkit-keyframes spinner-chase{
    100%{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@keyframes spinner-chase{
    100%{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-webkit-keyframes chase-dot{
    100%,80%{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@keyframes chase-dot{
    100%,80%{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-webkit-keyframes chase-dot-before{
    50%{
        -webkit-transform:scale(.4);
        transform:scale(.4)
    }
    0%,100%{
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
@keyframes chase-dot-before{
    50%{
        -webkit-transform:scale(.4);
        transform:scale(.4)
    }
    0%,100%{
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
[type=email]::-webkit-input-placeholder,[type=number]::-webkit-input-placeholder,[type=tel]::-webkit-input-placeholder,[type=url]::-webkit-input-placeholder{
    text-align:left
}
[type=email]::-moz-placeholder,[type=number]::-moz-placeholder,[type=tel]::-moz-placeholder,[type=url]::-moz-placeholder{
    text-align:left
}
[type=email]:-ms-input-placeholder,[type=number]:-ms-input-placeholder,[type=tel]:-ms-input-placeholder,[type=url]:-ms-input-placeholder{
    text-align:left
}
[type=email]::-ms-input-placeholder,[type=number]::-ms-input-placeholder,[type=tel]::-ms-input-placeholder,[type=url]::-ms-input-placeholder{
    text-align:left
}
[type=email]::placeholder,[type=number]::placeholder,[type=tel]::placeholder,[type=url]::placeholder{
    text-align:left
}
.form-check{
    position:relative;
    text-align:left
}
.form-check-right{
    padding-left:0;
    display:inline-block;
    padding-right:1.5em
}
.form-check-right .form-check-input{
    float:right;
    margin-left:0;
    margin-right:-1.5em
}
.form-check-right .form-check-label{
    display:block
}
.form-checkbox-outline .form-check-input{
    border-width:2px;
    background-color:#fff
}
.form-checkbox-outline .form-check-input:active{
    -webkit-filter:none;
    filter:none
}
.form-checkbox-outline .form-check-input:checked{
    background-color:#fff!important
}
.form-checkbox-outline .form-check-input:checked[type=checkbox]{
    background-image:none
}
.form-checkbox-outline .form-check-input:checked:after{
    position:absolute;
    content:'\F012C';
    font-family:"Material Design Icons";
    top:-4px!important;
    left:1px;
    font-size:16px;
    color:#343a40
}
.form-radio-outline .form-check-input{
    background-color:#fff;
    position:relative
}
.form-radio-outline .form-check-input:active{
    -webkit-filter:none;
    filter:none
}
.form-radio-outline .form-check-input:checked{
    background-color:#fff!important
}
.form-radio-outline .form-check-input:checked[type=checkbox]{
    background-image:none
}
.form-radio-outline .form-check-input:checked:after{
    position:absolute;
    content:'';
    top:3px!important;
    left:3px;
    width:5px;
    height:5px;
    border-radius:50%
}
.form-check-primary .form-check-input:checked{
    background-color:#556ee6;
    border-color:#556ee6
}
.form-radio-primary .form-check-input:checked{
    border-color:#556ee6;
    background-color:#556ee6
}
.form-radio-primary .form-check-input:checked:after{
    background-color:#556ee6
}
.form-check-secondary .form-check-input:checked{
    background-color:#74788d;
    border-color:#74788d
}
.form-radio-secondary .form-check-input:checked{
    border-color:#74788d;
    background-color:#74788d
}
.form-radio-secondary .form-check-input:checked:after{
    background-color:#74788d
}
.form-check-success .form-check-input:checked{
    background-color:#34c38f;
    border-color:#34c38f
}
.form-radio-success .form-check-input:checked{
    border-color:#34c38f;
    background-color:#34c38f
}
.form-radio-success .form-check-input:checked:after{
    background-color:#34c38f
}
.form-check-info .form-check-input:checked{
    background-color:#50a5f1;
    border-color:#50a5f1
}
.form-radio-info .form-check-input:checked{
    border-color:#50a5f1;
    background-color:#50a5f1
}
.form-radio-info .form-check-input:checked:after{
    background-color:#50a5f1
}
.form-check-warning .form-check-input:checked{
    background-color:#f1b44c;
    border-color:#f1b44c
}
.form-radio-warning .form-check-input:checked{
    border-color:#f1b44c;
    background-color:#f1b44c
}
.form-radio-warning .form-check-input:checked:after{
    background-color:#f1b44c
}
.form-check-danger .form-check-input:checked{
    background-color:#f46a6a;
    border-color:#f46a6a
}
.form-radio-danger .form-check-input:checked{
    border-color:#f46a6a;
    background-color:#f46a6a
}
.form-radio-danger .form-check-input:checked:after{
    background-color:#f46a6a
}
.form-check-pink .form-check-input:checked{
    background-color:#e83e8c;
    border-color:#e83e8c
}
.form-radio-pink .form-check-input:checked{
    border-color:#e83e8c;
    background-color:#e83e8c
}
.form-radio-pink .form-check-input:checked:after{
    background-color:#e83e8c
}
.form-check-light .form-check-input:checked{
    background-color:#eff2f7;
    border-color:#eff2f7
}
.form-radio-light .form-check-input:checked{
    border-color:#eff2f7;
    background-color:#eff2f7
}
.form-radio-light .form-check-input:checked:after{
    background-color:#eff2f7
}
.form-check-dark .form-check-input:checked{
    background-color:#343a40;
    border-color:#343a40
}
.form-radio-dark .form-check-input:checked{
    border-color:#343a40;
    background-color:#343a40
}
.form-radio-dark .form-check-input:checked:after{
    background-color:#343a40
}
.form-check,.form-check-input,.form-check-label{
    cursor:pointer;
    margin-bottom:0
}
.form-switch-md{
    padding-left:2.5rem;
    min-height:24px;
    line-height:24px
}
.form-switch-md .form-check-input{
    width:40px;
    height:20px;
    left:-.5rem;
    position:relative
}
.form-switch-md .form-check-label{
    vertical-align:middle
}
.form-switch-lg{
    padding-left:2.75rem;
    min-height:28px;
    line-height:28px
}
.form-switch-lg .form-check-input{
    width:48px;
    height:24px;
    left:-.75rem;
    position:relative
}
.input-group-text{
    margin-bottom:0
}
.mini-stats-wid .mini-stat-icon{
    overflow:hidden;
    position:relative
}
.mini-stats-wid .mini-stat-icon:after,.mini-stats-wid .mini-stat-icon:before{
    content:"";
    position:absolute;
    width:8px;
    height:54px;
    background-color:rgba(255,255,255,.1);
    left:16px;
    -webkit-transform:rotate(32deg);
    transform:rotate(32deg);
    top:-5px;
    -webkit-transition:all .4s;
    transition:all .4s
}
.mini-stats-wid .mini-stat-icon::after{
    left:-12px;
    width:12px;
    -webkit-transition:all .2s;
    transition:all .2s
}
.mini-stats-wid:hover .mini-stat-icon::after{
    left:60px
}
.mfp-popup-form{
    max-width:1140px
}
.mfp-close{
    color:#343a40!important
}
.bs-example-modal{
    position:relative;
    top:auto;
    right:auto;
    bottom:auto;
    left:auto;
    z-index:1;
    display:block
}
[dir=rtl] .modal-open{
    padding-left:0!important
}
.icon-demo-content{
    text-align:center;
    color:#adb5bd
}
.icon-demo-content i{
    display:block;
    font-size:24px;
    margin-bottom:16px;
    color:#74788d;
    -webkit-transition:all .4s;
    transition:all .4s
}
.icon-demo-content .col-lg-4{
    margin-top:24px
}
.icon-demo-content .col-lg-4:hover i{
    color:#556ee6;
    -webkit-transform:scale(1.5);
    transform:scale(1.5)
}
.grid-structure .grid-container{
    background-color:#f8f9fa;
    margin-top:10px;
    font-size:.8rem;
    font-weight:500;
    padding:10px 20px
}
.card-radio{
    background-color:#fff;
    border:2px solid #f6f6f6;
    border-radius:.25rem;
    padding:1rem;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
}
.card-radio:hover{
    cursor:pointer
}
.card-radio-label{
    display:block
}
.card-radio-input{
    display:none
}
.card-radio-input:checked+.card-radio{
    border-color:#556ee6!important
}
.navs-carousel .owl-nav{
    margin-top:16px
}
.navs-carousel .owl-nav button{
    width:30px;
    height:30px;
    line-height:28px!important;
    font-size:20px!important;
    border-radius:50%!important;
    background-color:rgba(85,110,230,.25)!important;
    color:#556ee6!important;
    margin:4px 8px!important
}
@media print{
    .footer,.navbar-header,.page-title-box,.right-bar,.vertical-menu{
        display:none!important
    }
    .card-body,.main-content,.page-content,.right-bar,body{
        padding:0;
        margin:0
    }
    .card{
        border:0
    }
}
[data-simplebar]{
    position:relative;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-pack:start;
    -ms-flex-pack:start;
    justify-content:flex-start;
    -ms-flex-line-pack:start;
    align-content:flex-start;
    -webkit-box-align:start;
    -ms-flex-align:start;
    align-items:flex-start
}
.simplebar-wrapper{
    overflow:hidden;
    width:inherit;
    height:inherit;
    max-width:inherit;
    max-height:inherit
}
.simplebar-mask{
    direction:inherit;
    position:absolute;
    overflow:hidden;
    padding:0;
    margin:0;
    left:0;
    top:0;
    bottom:0;
    right:0;
    width:auto!important;
    height:auto!important;
    z-index:0
}
.simplebar-offset{
    direction:inherit!important;
    -webkit-box-sizing:inherit!important;
    box-sizing:inherit!important;
    resize:none!important;
    position:absolute;
    top:0;
    left:0!important;
    bottom:0;
    right:0!important;
    padding:0;
    margin:0;
    -webkit-overflow-scrolling:touch
}
.simplebar-content-wrapper{
    direction:inherit;
    -webkit-box-sizing:border-box!important;
    box-sizing:border-box!important;
    position:relative;
    display:block;
    height:100%;
    width:auto;
    visibility:visible;
    overflow:auto;
    max-width:100%;
    max-height:100%;
    scrollbar-width:none;
    padding:0!important
}
.simplebar-content-wrapper::-webkit-scrollbar,.simplebar-hide-scrollbar::-webkit-scrollbar{
    display:none
}
.simplebar-content:after,.simplebar-content:before{
    content:' ';
    display:table
}
.simplebar-placeholder{
    max-height:100%;
    max-width:100%;
    width:100%;
    pointer-events:none
}
.simplebar-height-auto-observer-wrapper{
    -webkit-box-sizing:inherit!important;
    box-sizing:inherit!important;
    height:100%;
    width:100%;
    max-width:1px;
    position:relative;
    float:left;
    max-height:1px;
    overflow:hidden;
    z-index:-1;
    padding:0;
    margin:0;
    pointer-events:none;
    -webkit-box-flex:inherit;
    -ms-flex-positive:inherit;
    flex-grow:inherit;
    -ms-flex-negative:0;
    flex-shrink:0;
    -ms-flex-preferred-size:0;
    flex-basis:0
}
.simplebar-height-auto-observer{
    -webkit-box-sizing:inherit;
    box-sizing:inherit;
    display:block;
    opacity:0;
    position:absolute;
    top:0;
    left:0;
    height:1000%;
    width:1000%;
    min-height:1px;
    min-width:1px;
    overflow:hidden;
    pointer-events:none;
    z-index:-1
}
.simplebar-track{
    z-index:1;
    position:absolute;
    right:0;
    bottom:0;
    pointer-events:none;
    overflow:hidden
}
[data-simplebar].simplebar-dragging .simplebar-content{
    pointer-events:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    -webkit-user-select:none
}
[data-simplebar].simplebar-dragging .simplebar-track{
    pointer-events:all
}
.simplebar-scrollbar{
    position:absolute;
    right:2px;
    width:4px;
    min-height:10px
}
.simplebar-scrollbar:before{
    position:absolute;
    content:'';
    background:#a2adb7;
    border-radius:7px;
    left:0;
    right:0;
    opacity:0;
    -webkit-transition:opacity .2s linear;
    transition:opacity .2s linear
}
.simplebar-scrollbar.simplebar-visible:before{
    opacity:.5;
    -webkit-transition:opacity 0s linear;
    transition:opacity 0s linear
}
.simplebar-track.simplebar-vertical{
    top:0;
    width:11px
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before{
    top:2px;
    bottom:2px
}
.simplebar-track.simplebar-horizontal{
    left:0;
    height:11px
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before{
    height:100%;
    left:2px;
    right:2px
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar{
    right:auto;
    left:0;
    top:2px;
    height:7px;
    min-height:0;
    min-width:10px;
    width:auto
}
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical{
    right:auto;
    left:0
}
.hs-dummy-scrollbar-size{
    direction:rtl;
    position:fixed;
    opacity:0;
    visibility:hidden;
    height:500px;
    width:500px;
    overflow-y:hidden;
    overflow-x:scroll
}
.simplebar-hide-scrollbar{
    position:fixed;
    left:0;
    visibility:hidden;
    overflow-y:scroll;
    scrollbar-width:none
}
.custom-scroll{
    height:100%
}
.lnb-calendars-item{
    display:inline-block;
    margin-right:7px
}
input[type=checkbox].tui-full-calendar-checkbox-round+span{
    margin-right:4px;
    margin-left:0
}
.tui-full-calendar-layout,.tui-full-calendar-timegrid-timezone{
    background-color:#fff!important
}
.tui-full-calendar-dayname-container,.tui-full-calendar-left,.tui-full-calendar-splitter,.tui-full-calendar-time-date,.tui-full-calendar-timegrid-gridline,.tui-full-calendar-timegrid-timezone,.tui-full-calendar-weekday-grid-line{
    border-color:#f6f6f6!important
}
.tui-full-calendar-weekday-exceed-in-week{
    text-align:center;
    width:30px;
    height:30px;
    line-height:28px;
    border-radius:4px;
    background-color:#fff;
    color:#495057;
    border-color:#eff2f7
}
.tui-full-calendar-timegrid-hour{
    color:#495057!important
}
.tui-full-calendar-weekday-schedule-title{
    color:#212529!important
}
.tui-full-calendar-weekday-schedule-title .tui-full-calendar-time-schedule{
    font-weight:600
}
.tui-full-calendar-popup-container{
    background-color:#fff!important;
    border-color:#eff2f7!important
}
.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-fill{
    border-top-color:#eff2f7!important
}
.tui-full-calendar-arrow-top .tui-full-calendar-popup-arrow-fill{
    border-bottom-color:#eff2f7!important
}
.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-borde{
    border-bottom-color:#eff2f7!important
}
.tui-full-calendar-button{
    color:#495057;
    background-color:#fff!important;
    border-color:#ced4da
}
.tui-full-calendar-popup-section-item{
    border-color:#ced4da
}
.tui-full-calendar-dropdown-menu,.tui-full-calendar-dropdown-menu-item{
    background-color:#fff!important
}
.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-border{
    border-top-color:#eff2f7
}
.tui-full-calendar-content{
    background-color:#fff!important;
    color:#495057!important
}
.tui-full-calendar-confirm{
    background-color:#f46a6a!important;
    color:#fff!important
}
.tui-full-calendar-confirm:hover{
    background-color:#c35555!important;
    color:#fff!important
}
.tui-full-calendar-month-dayname-item span{
    color:#212529!important
}
.tui-full-calendar-weekday-grid-line.tui-full-calendar-near-month-day.tui-full-calendar-extra-date .tui-full-calendar-weekday-grid-header .tui-full-calendar-weekday-grid-date{
    color:#ced4da!important
}
.tui-full-calendar-weekday-grid-line.tui-full-calendar-near-month-day .tui-full-calendar-weekday-grid-header .tui-full-calendar-weekday-grid-date{
    color:#343a40!important
}
.fc td,.fc th{
    border:1px solid #eff2f7
}
.fc .fc-toolbar h2{
    font-size:16px;
    line-height:30px;
    text-transform:uppercase
}
@media (max-width:767.98px){
    .fc .fc-toolbar .fc-center,.fc .fc-toolbar .fc-left,.fc .fc-toolbar .fc-right{
        float:none;
        display:block;
        text-align:center;
        clear:both;
        margin:10px 0
    }
    .fc .fc-toolbar>*>*{
        float:none
    }
    .fc .fc-toolbar .fc-today-button{
        display:none
    }
}
.fc .fc-toolbar .btn{
    text-transform:capitalize
}
.fc th.fc-widget-header{
    background:#f6f6f6;
    color:#495057;
    line-height:20px;
    padding:10px 0;
    text-transform:uppercase;
    font-weight:600
}
.fc-unthemed .fc-content,.fc-unthemed .fc-divider,.fc-unthemed .fc-list-heading td,.fc-unthemed .fc-list-view,.fc-unthemed .fc-popover,.fc-unthemed .fc-row,.fc-unthemed tbody,.fc-unthemed td,.fc-unthemed th,.fc-unthemed thead{
    border-color:#f6f6f6
}
.fc-unthemed td.fc-today{
    background:#f6f8fa
}
.fc-button{
    background:#fff;
    border-color:#eff2f7;
    color:#495057;
    text-transform:capitalize;
    -webkit-box-shadow:none;
    box-shadow:none;
    padding:6px 12px!important;
    height:auto!important
}
.fc-state-active,.fc-state-disabled,.fc-state-down{
    background-color:#556ee6;
    color:#fff;
    text-shadow:none
}
.fc-event{
    border-radius:2px;
    border:none;
    cursor:move;
    font-size:.8125rem;
    margin:5px 7px;
    padding:5px 5px;
    text-align:center
}
.fc-event,.fc-event-dot{
    background-color:#556ee6
}
.fc-event .fc-content{
    color:#fff
}
#external-events .external-event{
    text-align:left;
    padding:8px 16px
}
.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark .fc-content{
    color:#eff2f7
}
[dir=rtl] .fc-header-toolbar{
    direction:ltr!important
}
[dir=rtl] .fc-toolbar>*>:not(:first-child){
    margin-left:.75em
}
.task-box{
    border:1px solid #f6f6f6
}
.gu-transit{
    border:1px dashed #74788d!important;
    background-color:#eff2f7!important
}
#session-timeout-dialog .close{
    display:none
}
#session-timeout-dialog .countdown-holder{
    color:#f46a6a;
    font-weight:500
}
#session-timeout-dialog .btn-default{
    background-color:#fff;
    color:#f46a6a;
    -webkit-box-shadow:none;
    box-shadow:none
}
.irs{
    font-family:var(--bs-font-sans-serif)
}
.irs--square{
    cursor:pointer
}
.irs--square .irs-bar,.irs--square .irs-from,.irs--square .irs-single,.irs--square .irs-to{
    background:#556ee6!important;
    font-size:11px
}
.irs--square .irs-from:before,.irs--square .irs-single:before,.irs--square .irs-to:before{
    border-top-color:#556ee6
}
.irs--square .irs-line{
    background:#f6f6f6;
    border-color:#f6f6f6
}
.irs--square .irs-grid-text{
    font-size:11px;
    color:#ced4da
}
.irs--square .irs-max,.irs--square .irs-min{
    color:#ced4da;
    background:#f6f6f6;
    font-size:11px
}
.irs--square .irs-handle{
    border:2px solid #556ee6;
    width:12px;
    height:12px;
    top:26px;
    background-color:#fff!important;
    cursor:pointer
}
.irs--square .irs-handle:active{
    cursor:pointer
}
.swal2-container .swal2-title{
    font-size:20px;
    font-weight:500
}
.swal2-modal{
    font-size:14px
}
.swal2-icon.swal2-question{
    border-color:#50a5f1;
    color:#50a5f1
}
.swal2-icon.swal2-success [class^=swal2-success-line]{
    background-color:#34c38f
}
.swal2-icon.swal2-success .swal2-success-ring{
    border-color:rgba(52,195,143,.3)
}
.swal2-icon.swal2-warning{
    border-color:#f1b44c;
    color:#f1b44c
}
.swal2-styled:focus{
    -webkit-box-shadow:none;
    box-shadow:none
}
.swal2-progress-steps .swal2-progress-step{
    background:#556ee6
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step{
    background:#556ee6
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step,.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step-line{
    background:rgba(85,110,230,.3)
}
.swal2-progress-steps .swal2-progress-step-line{
    background:#556ee6
}
.swal2-loader{
    border-color:#556ee6 transparent #556ee6 transparent
}
.symbol{
    border-color:#fff
}
.rating-symbol-background,.rating-symbol-foreground{
    font-size:24px
}
.rating-symbol-foreground{
    top:0
}
.rating-star>span{
    display:inline-block;
    vertical-align:middle
}
.rating-star>span.badge{
    margin-left:4px
}
#toast-container>div{
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    opacity:1
}
#toast-container>div:hover{
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    opacity:.9
}
#toast-container.toast-bottom-full-width>div,#toast-container.toast-top-full-width>div{
    min-width:96%;
    margin:4px auto
}
.toast-primary{
    border:2px solid #556ee6!important;
    background-color:rgba(85,110,230,.8)!important
}
.toast-secondary{
    border:2px solid #74788d!important;
    background-color:rgba(116,120,141,.8)!important
}
.toast-success{
    border:2px solid #34c38f!important;
    background-color:rgba(52,195,143,.8)!important
}
.toast-info{
    border:2px solid #50a5f1!important;
    background-color:rgba(80,165,241,.8)!important
}
.toast-warning{
    border:2px solid #f1b44c!important;
    background-color:rgba(241,180,76,.8)!important
}
.toast-danger{
    border:2px solid #f46a6a!important;
    background-color:rgba(244,106,106,.8)!important
}
.toast-pink{
    border:2px solid #e83e8c!important;
    background-color:rgba(232,62,140,.8)!important
}
.toast-light{
    border:2px solid #eff2f7!important;
    background-color:rgba(239,242,247,.8)!important
}
.toast-dark{
    border:2px solid #343a40!important;
    background-color:rgba(52,58,64,.8)!important
}
.toast-error{
    background-color:rgba(244,106,106,.8);
    border:2px solid #f46a6a
}
.toastr-options{
    padding:24px;
    background-color:#f6f8fa;
    margin-bottom:0;
    border:1px solid #eff2f7
}
.error{
    color:#f46a6a
}
.parsley-error{
    border-color:#f46a6a
}
.parsley-errors-list{
    display:none;
    margin:0;
    padding:0
}
.parsley-errors-list.filled{
    display:block
}
.parsley-errors-list>li{
    font-size:12px;
    list-style:none;
    color:#f46a6a;
    margin-top:5px
}
.select2-container .select2-selection--single{
    background-color:#fff;
    border:1px solid #ced4da;
    height:38px
}
.select2-container .select2-selection--single:focus{
    outline:0
}
.select2-container .select2-selection--single .select2-selection__rendered{
    line-height:36px;
    padding-left:.75rem;
    color:#495057
}
.select2-container .select2-selection--single .select2-selection__arrow{
    height:34px;
    width:34px;
    right:3px
}
.select2-container .select2-selection--single .select2-selection__arrow b{
    border-color:#adb5bd transparent transparent transparent;
    border-width:6px 6px 0 6px
}
.select2-container .select2-selection--single .select2-selection__placeholder{
    color:#495057
}
[dir=rtl] .select2-selection__rendered{
    text-align:end
}
.select2-container--open .select2-selection--single .select2-selection__arrow b{
    border-color:transparent transparent #adb5bd transparent!important;
    border-width:0 6px 6px 6px!important
}
.select2-container--default .select2-search--dropdown{
    padding:10px;
    background-color:#fff
}
.select2-container--default .select2-search--dropdown .select2-search__field{
    border:1px solid #ced4da;
    background-color:#fff;
    color:#74788d;
    outline:0
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color:#556ee6
}
.select2-container--default .select2-results__option[aria-selected=true]{
    background-color:#f8f9fa;
    color:#343a40
}
.select2-container--default .select2-results__option[aria-selected=true]:hover{
    background-color:#556ee6;
    color:#fff
}
.select2-results__option{
    padding:6px 12px
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered{
    padding-left:.75rem
}
.select2-dropdown{
    border:1px solid rgba(0,0,0,.15);
    background-color:#fff;
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03)
}
.select2-search input{
    border:1px solid #f6f6f6
}
.select2-container .select2-selection--multiple{
    min-height:38px;
    background-color:#fff;
    border:1px solid #ced4da!important
}
.select2-container .select2-selection--multiple .select2-selection__rendered{
    padding:2px .75rem
}
.select2-container .select2-selection--multiple .select2-search__field{
    border:0;
    color:#495057
}
.select2-container .select2-selection--multiple .select2-search__field::-webkit-input-placeholder{
    color:#495057
}
.select2-container .select2-selection--multiple .select2-search__field::-moz-placeholder{
    color:#495057
}
.select2-container .select2-selection--multiple .select2-search__field:-ms-input-placeholder{
    color:#495057
}
.select2-container .select2-selection--multiple .select2-search__field::-ms-input-placeholder{
    color:#495057
}
.select2-container .select2-selection--multiple .select2-search__field::placeholder{
    color:#495057
}
.select2-container .select2-selection--multiple .select2-selection__choice{
    background-color:#eff2f7;
    border:1px solid #f6f6f6;
    border-radius:1px;
    padding:0 7px
}
.select2-container--default.select2-container--focus .select2-selection--multiple{
    border-color:#ced4da
}
.select2-container--default .select2-results__group{
    font-weight:600
}
.select2-result-repository__avatar{
    float:left;
    width:60px;
    margin-right:10px
}
.select2-result-repository__avatar img{
    width:100%;
    height:auto;
    border-radius:2px
}
.select2-result-repository__statistics{
    margin-top:7px
}
.select2-result-repository__forks,.select2-result-repository__stargazers,.select2-result-repository__watchers{
    display:inline-block;
    font-size:11px;
    margin-right:1em;
    color:#adb5bd
}
.select2-result-repository__forks .fa,.select2-result-repository__stargazers .fa,.select2-result-repository__watchers .fa{
    margin-right:4px
}
.select2-result-repository__forks .fa.fa-flash::before,.select2-result-repository__stargazers .fa.fa-flash::before,.select2-result-repository__watchers .fa.fa-flash::before{
    content:"\f0e7";
    font-family:"Font Awesome 5 Free"
}
.select2-results__option--highlighted .select2-result-repository__forks,.select2-results__option--highlighted .select2-result-repository__stargazers,.select2-results__option--highlighted .select2-result-repository__watchers{
    color:rgba(255,255,255,.8)
}
.select2-result-repository__meta{
    overflow:hidden
}
.img-flag{
    margin-right:7px;
    height:15px;
    width:18px
}
input[switch]{
    display:none
}
input[switch]+label{
    font-size:1em;
    line-height:1;
    width:56px;
    height:24px;
    background-color:#ced4da;
    background-image:none;
    border-radius:2rem;
    padding:.16667rem;
    cursor:pointer;
    display:inline-block;
    text-align:center;
    position:relative;
    font-weight:500;
    -webkit-transition:all .1s ease-in-out;
    transition:all .1s ease-in-out
}
input[switch]+label:before{
    color:#343a40;
    content:attr(data-off-label);
    display:block;
    font-family:inherit;
    font-weight:500;
    font-size:12px;
    line-height:21px;
    position:absolute;
    right:1px;
    margin:3px;
    top:-2px;
    text-align:center;
    min-width:1.66667rem;
    overflow:hidden;
    -webkit-transition:all .1s ease-in-out;
    transition:all .1s ease-in-out
}
input[switch]+label:after{
    content:'';
    position:absolute;
    left:3px;
    background-color:#eff2f7;
    -webkit-box-shadow:none;
    box-shadow:none;
    border-radius:2rem;
    height:20px;
    width:20px;
    top:2px;
    -webkit-transition:all .1s ease-in-out;
    transition:all .1s ease-in-out
}
input[switch]:checked+label{
    background-color:#556ee6
}
input[switch]:checked+label{
    background-color:#556ee6
}
input[switch]:checked+label:before{
    color:#fff;
    content:attr(data-on-label);
    right:auto;
    left:3px
}
input[switch]:checked+label:after{
    left:33px;
    background-color:#eff2f7
}
input[switch=bool]+label{
    background-color:#f46a6a
}
input[switch=bool]+label:before,input[switch=bool]:checked+label:before,input[switch=default]:checked+label:before{
    color:#fff
}
input[switch=bool]:checked+label{
    background-color:#34c38f
}
input[switch=default]:checked+label{
    background-color:#a2a2a2
}
input[switch=primary]:checked+label{
    background-color:#556ee6
}
input[switch=success]:checked+label{
    background-color:#34c38f
}
input[switch=info]:checked+label{
    background-color:#50a5f1
}
input[switch=warning]:checked+label{
    background-color:#f1b44c
}
input[switch=danger]:checked+label{
    background-color:#f46a6a
}
input[switch=dark]:checked+label{
    background-color:#343a40
}
input[switch=dark]:checked+label:before{
    color:#eff2f7
}
.square-switch{
    margin-right:7px
}
.square-switch input[switch]+label,.square-switch input[switch]+label:after{
    border-radius:4px
}
.sp-container{
    background-color:#fff
}
.sp-container button{
    padding:.25rem .5rem;
    font-size:.71094rem;
    border-radius:.2rem;
    font-weight:400;
    color:#343a40
}
.sp-container button.sp-palette-toggle{
    background-color:#eff2f7
}
.sp-container button.sp-choose{
    background-color:#34c38f;
    margin-left:5px;
    margin-right:0
}
.sp-palette-container{
    border-right:1px solid #eff2f7
}
.sp-input{
    background-color:#fff;
    border-color:#ced4da!important;
    color:#495057
}
.sp-input:focus{
    outline:0
}
[dir=rtl] .sp-alpha{
    direction:ltr
}
[dir=rtl] .sp-original-input-container .sp-add-on{
    border-top-right-radius:0!important;
    border-bottom-right-radius:0!important;
    border-top-left-radius:4px!important;
    border-bottom-left-radius:4px!important
}
[dir=rtl] input.spectrum.with-add-on{
    border:1px solid #ced4da;
    border-left:0;
    border-top-left-radius:0;
    border-bottom-left-radius:0;
    border-top-right-radius:.25rem;
    border-bottom-right-radius:.25rem
}
.bootstrap-timepicker-widget table td a{
    color:#495057
}
.bootstrap-timepicker-widget table td a:hover{
    background-color:transparent;
    border-color:transparent;
    border-radius:4px;
    color:#556ee6;
    text-decoration:none
}
.bootstrap-timepicker-widget table td input{
    width:32px;
    height:32px;
    border:0;
    color:#495057;
    border:1px solid #eff2f7;
    background-color:#fff
}
.bootstrap-timepicker-widget.dropdown-menu:after{
    border-bottom-color:#eff2f7
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after{
    border-top-color:#eff2f7
}
.timepicker-orient-top{
    top:calc(1.5em + .94rem + 2px)!important
}
.timepicker-orient-bottom{
    top:auto!important;
    bottom:calc(1.5em + .94rem + 2px)!important
}
.bootstrap-timepicker-widget{
    left:0!important;
    right:auto!important
}
.bootstrap-timepicker-widget.timepicker-orient-left:before{
    left:6px;
    right:auto
}
.bootstrap-timepicker-widget.timepicker-orient-left::after{
    left:7px;
    right:auto
}
.datepicker{
    border:1px solid #eff2f7;
    padding:8px;
    z-index:999!important
}
.datepicker table tr th{
    font-weight:500
}
.datepicker table tr td .active.disabled,.datepicker table tr td span.active.active,.datepicker table tr td span.active:hover.active,.datepicker table tr td.active,.datepicker table tr td.active.disabled:hover,.datepicker table tr td.active:hover,.datepicker table tr td.selected,.datepicker table tr td.selected.disabled,.datepicker table tr td.selected.disabled:hover,.datepicker table tr td.selected:hover,.datepicker table tr td.today,.datepicker table tr td.today.disabled,.datepicker table tr td.today.disabled:hover,.datepicker table tr td.today:hover{
    background-color:#556ee6!important;
    background-image:none;
    -webkit-box-shadow:none;
    box-shadow:none;
    color:#fff!important
}
.datepicker table tr td span.focused,.datepicker table tr td span:hover,.datepicker table tr td.day.focused,.datepicker table tr td.day:hover{
    background:#eff2f7
}
.datepicker table tr td span.new,.datepicker table tr td span.old,.datepicker table tr td.new,.datepicker table tr td.old{
    color:#adb5bd;
    opacity:.6
}
.datepicker table tr td.range,.datepicker table tr td.range.disabled,.datepicker table tr td.range.disabled:hover,.datepicker table tr td.range:hover{
    background-color:#f6f6f6
}
.table-condensed>tbody>tr>td,.table-condensed>thead>tr>th{
    padding:7px
}
.bootstrap-datepicker-inline .datepicker-inline{
    width:auto!important;
    display:inline-block
}
.datepicker-container{
    border:1px solid #eff2f7;
    -webkit-box-shadow:none;
    box-shadow:none;
    background-color:#fff
}
.datepicker-container.datepicker-inline{
    width:212px
}
.datepicker-panel>ul>li{
    background-color:#fff;
    border-radius:4px
}
.datepicker-panel>ul>li.picked,.datepicker-panel>ul>li.picked:hover{
    background-color:rgba(85,110,230,.25);
    color:#556ee6
}
.datepicker-panel>ul>li.highlighted,.datepicker-panel>ul>li.highlighted:hover,.datepicker-panel>ul>li:hover{
    background-color:#556ee6;
    color:#fff
}
.datepicker-panel>ul>li.muted,.datepicker-panel>ul>li.muted:hover{
    color:#adb5bd;
    opacity:.6
}
.datepicker-panel>ul[data-view=week]>li{
    font-weight:500
}
.datepicker-panel>ul[data-view=week]>li,.datepicker-panel>ul[data-view=week]>li:hover{
    background-color:#fff
}
.bootstrap-touchspin.input-group>.input-group-prepend>.btn,.bootstrap-touchspin.input-group>.input-group-prepend>.input-group-text{
    border-top-right-radius:0;
    border-bottom-right-radius:0
}
.bootstrap-touchspin.input-group>.input-group-append>.btn,.bootstrap-touchspin.input-group>.input-group-append>.input-group-text{
    border-top-left-radius:0;
    border-bottom-left-radius:0
}
.bootstrap-touchspin .input-group-btn-vertical{
    right:0
}
.bootstrap-touchspin .input-group-btn-vertical .btn{
    right:0!important;
    left:100%!important
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up{
    border-top-right-radius:4px!important;
    border-bottom-right-radius:0!important;
    border-top-left-radius:0!important;
    border-bottom-left-radius:0!important
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down{
    border-top-right-radius:0!important;
    border-bottom-right-radius:4px!important;
    border-top-left-radius:0!important;
    border-bottom-left-radius:0!important
}
.tox-tinymce{
    border:1px solid #ced4da!important
}
.tox .tox-collection__item{
    color:#212529!important
}
.tox .tox-collection--toolbar.tox-collection--toolbar-lg.tox-selected-menu,.tox .tox-menu.tox-collection.tox-collection--list.tox-selected-menu,.tox .tox-swatches-menu.tox-selected-menu{
    -webkit-box-shadow:0 .125rem .25rem rgba(0,0,0,.075)!important;
    box-shadow:0 .125rem .25rem rgba(0,0,0,.075)!important;
    -webkit-animation-name:DropDownSlide!important;
    animation-name:DropDownSlide!important;
    -webkit-animation-duration:.3s!important;
    animation-duration:.3s!important;
    -webkit-animation-fill-mode:both!important;
    animation-fill-mode:both!important;
    margin:0!important;
    position:absolute!important;
    z-index:1000!important;
    padding:.5rem 0!important;
    background-color:#fff!important;
    border:0 solid rgba(0,0,0,.15)!important;
    border-radius:.25rem!important
}
.tox .tox-collection--list .tox-collection__group{
    border-color:#eff2f7!important
}
.tox .tox-collection--list .tox-collection__item--active{
    color:#1e2125!important;
    background-color:#f8f9fa!important
}
.tox .tox-collection__group-heading{
    color:#1e2125!important;
    background-color:#f8f9fa!important
}
.tox .tox-statusbar{
    border-top:1px solid #eff2f7!important
}
.tox .tox-edit-area__iframe,.tox .tox-menubar,.tox .tox-statusbar{
    background-color:#fff!important;
    background:0 0!important
}
.tox .tox-mbtn{
    color:#495057!important
}
.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active){
    background-color:#eff2f7!important
}
.tox .tox-tbtn:hover{
    background-color:transparent!important
}
.tox .tox-toolbar,.tox .tox-toolbar__overflow,.tox .tox-toolbar__primary{
    background:#eff2f7!important
}
.tox .tox-toolbar__primary{
    border-top-color:#eff2f7!important
}
.tox .tox-tbtn--enabled{
    background:0 0!important
}
.tox tox-split-button:focus{
    background:0 0!important
}
.tox .tox-tbtn{
    color:transparent!important
}
.tox .tox-tbtn svg{
    fill:#495057!important
}
.tox .tox-edit-area__iframe{
    background-color:#fff!important
}
.tox .tox-statusbar a,.tox .tox-statusbar__path-item,.tox .tox-statusbar__wordcount{
    color:#495057!important
}
.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type){
    border-right:1px solid #dee4ef!important
}
.tox .tox-dialog{
    background-color:#fff!important
}
.tox .tox-button:hover{
    background-color:#556ee6!important;
    background-image:none!important;
    border-color:#556ee6!important;
    -webkit-box-shadow:none!important;
    box-shadow:none!important
}
.tox .tox-collection--toolbar .tox-collection__item--active{
    background-color:#f6f6f6!important
}
.tox .tox-dialog__header{
    background-color:#fff!important
}
.tox .tox-dialog__footer{
    background-color:#fff!important
}
.tox .tox-icon{
    color:#343a40!important
}
.tox .tox-dialog__title{
    color:#74788d!important
}
.tox .tox-dialog__body-nav-item{
    color:#74788d!important
}
.tox .tox-label{
    color:#74788d!important
}
.tox .tox-textfield{
    background-color:#fff!important;
    color:#495057!important
}
.editable-input .form-control{
    display:inline-block
}
.editable-buttons{
    margin-left:7px
}
.editable-buttons .editable-cancel{
    margin-left:7px
}
.dropzone{
    min-height:230px;
    border:2px dashed #ced4da;
    background:#fff;
    border-radius:6px
}
.dropzone .dz-message{
    font-size:24px;
    width:100%
}
.form-wizard-wrapper label{
    font-size:14px;
    text-align:right
}
.wizard .steps>ul{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    padding-left:0;
    margin-bottom:0;
    list-style:none
}
@media (max-width:1199.98px){
    .wizard .steps>ul{
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column
    }
}
.wizard .steps>ul>a,.wizard .steps>ul>li{
    -ms-flex-preferred-size:0;
    flex-basis:0;
    -webkit-box-flex:1;
    -ms-flex-positive:1;
    flex-grow:1
}
.wizard .steps>ul>li{
    width:100%
}
.wizard .steps>ul>li a{
    display:block;
    padding:.5rem 1rem;
    color:#495057;
    font-weight:500;
    background-color:rgba(85,110,230,.1)
}
.wizard .steps>ul .current-info{
    position:absolute;
    left:-999em
}
.wizard .steps .number{
    display:inline-block;
    width:38px;
    height:38px;
    line-height:34px;
    border:2px solid #556ee6;
    color:#556ee6;
    text-align:center;
    border-radius:50%;
    margin-right:.5rem
}
.wizard .steps .current a,.wizard .steps .current a:active,.wizard .steps .current a:hover{
    background-color:rgba(85,110,230,.2);
    color:#495057
}
.wizard .steps .current a .number,.wizard .steps .current a:active .number,.wizard .steps .current a:hover .number{
    background-color:#556ee6;
    color:#fff
}
.wizard>.content{
    background-color:transparent;
    padding:14px;
    margin-top:0;
    border-radius:0;
    min-height:150px
}
.wizard>.content>.title{
    position:absolute;
    left:-999em
}
.wizard>.content>.body{
    width:100%;
    height:100%;
    padding:14px 0 0;
    position:static
}
.wizard>.actions{
    position:relative;
    display:block;
    text-align:right;
    width:100%
}
.wizard>.actions>ul{
    display:block;
    text-align:right;
    padding-left:0
}
.wizard>.actions>ul>li{
    display:inline-block;
    margin:0 .5em
}
.wizard>.actions a,.wizard>.actions a:active,.wizard>.actions a:hover{
    background-color:#556ee6;
    border-radius:4px;
    padding:8px 15px;
    color:#fff
}
.wizard>.actions .disabled a,.wizard>.actions .disabled a:active,.wizard>.actions .disabled a:hover{
    opacity:.65;
    background-color:#556ee6;
    color:#fff;
    cursor:not-allowed
}
.wizard.vertical-wizard{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap
}
.wizard.vertical-wizard .steps>ul{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column
}
.wizard.vertical-wizard .steps>ul>li{
    width:100%!important
}
.wizard.vertical-wizard .actions,.wizard.vertical-wizard .content,.wizard.vertical-wizard .steps{
    width:100%
}
@media (min-width:1200px){
    .wizard.vertical-wizard .steps{
        width:25%
    }
}
.wizard.vertical-wizard .content{
    padding:24px
}
@media (min-width:1200px){
    .wizard.vertical-wizard .content{
        width:75%;
        padding:12px 24px
    }
}
.wizard.vertical-wizard .content>.body{
    padding:0
}
div.dataTables_wrapper div.dataTables_filter{
    text-align:right
}
@media (max-width:767px){
    div.dataTables_wrapper div.dataTables_filter{
        text-align:center
    }
}
div.dataTables_wrapper div.dataTables_filter input{
    margin-left:.5em;
    margin-right:0
}
.table.dataTable.dtr-inline.collapsed>tbody>tr>td,table.dataTable.dtr-inline.collapsed>tbody>tr>td{
    position:relative
}
.table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control{
    padding-left:30px
}
.table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before{
    top:50%;
    left:5px;
    height:14px;
    width:14px;
    margin-top:-9px;
    display:block;
    position:absolute;
    color:#fff;
    border:2px solid #fff;
    border-radius:14px;
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    text-align:center;
    text-indent:0!important;
    line-height:14px;
    content:'+';
    background-color:#556ee6
}
.table-rep-plugin .btn-toolbar{
    display:block
}
.table-rep-plugin .table-responsive{
    border:none!important
}
.table-rep-plugin .btn-group .btn-default{
    background-color:#74788d;
    color:#eff2f7;
    border:1px solid #74788d
}
.table-rep-plugin .btn-group .btn-default.btn-primary{
    background-color:#556ee6;
    border-color:#556ee6;
    color:#fff;
    -webkit-box-shadow:0 0 0 2px rgba(85,110,230,.5);
    box-shadow:0 0 0 2px rgba(85,110,230,.5)
}
.table-rep-plugin .btn-group.pull-right{
    float:right
}
.table-rep-plugin .btn-group.pull-right .dropdown-menu{
    right:0;
    -webkit-transform:none!important;
    transform:none!important;
    top:100%!important
}
.table-rep-plugin tbody th{
    font-size:14px;
    font-weight:400
}
.table-rep-plugin .checkbox-row{
    padding-left:40px;
    color:#495057!important
}
.table-rep-plugin .checkbox-row:hover{
    background-color:#f6f8fa!important
}
.table-rep-plugin .checkbox-row label{
    display:inline-block;
    padding-left:5px;
    position:relative
}
.table-rep-plugin .checkbox-row label::before{
    -o-transition:.3s ease-in-out;
    -webkit-transition:.3s ease-in-out;
    background-color:#fff;
    border-radius:3px;
    border:1px solid #f6f6f6;
    content:"";
    display:inline-block;
    height:17px;
    left:0;
    margin-left:-20px;
    position:absolute;
    transition:.3s ease-in-out;
    width:17px;
    outline:0!important
}
.table-rep-plugin .checkbox-row label::after{
    color:#eff2f7;
    display:inline-block;
    font-size:11px;
    height:16px;
    left:0;
    margin-left:-20px;
    padding-left:3px;
    padding-top:1px;
    position:absolute;
    top:-1px;
    width:16px
}
.table-rep-plugin .checkbox-row input[type=checkbox]{
    cursor:pointer;
    opacity:0;
    z-index:1;
    outline:0!important
}
.table-rep-plugin .checkbox-row input[type=checkbox]:disabled+label{
    opacity:.65
}
.table-rep-plugin .checkbox-row input[type=checkbox]:focus+label::before{
    outline-offset:-2px;
    outline:0
}
.table-rep-plugin .checkbox-row input[type=checkbox]:checked+label::after{
    content:"\f00c";
    font-family:'Font Awesome 5 Free';
    font-weight:900
}
.table-rep-plugin .checkbox-row input[type=checkbox]:disabled+label::before{
    background-color:#f8f9fa;
    cursor:not-allowed
}
.table-rep-plugin .checkbox-row input[type=checkbox]:checked+label::before{
    background-color:#556ee6;
    border-color:#556ee6
}
.table-rep-plugin .checkbox-row input[type=checkbox]:checked+label::after{
    color:#fff
}
.table-rep-plugin .fixed-solution .sticky-table-header{
    top:70px!important;
    background-color:#556ee6
}
.table-rep-plugin .fixed-solution .sticky-table-header table{
    color:#fff
}
.table-rep-plugin .sticky-table-header,.table-rep-plugin table.focus-on tbody tr.focused td,.table-rep-plugin table.focus-on tbody tr.focused th{
    background:#556ee6;
    border-color:#556ee6;
    color:#fff
}
.table-rep-plugin .sticky-table-header table,.table-rep-plugin table.focus-on tbody tr.focused td table,.table-rep-plugin table.focus-on tbody tr.focused th table{
    color:#fff
}
@media (min-width:992px){
    body[data-layout=horizontal] .fixed-solution .sticky-table-header{
        top:120px!important
    }
}
.table-edits input,.table-edits select{
    height:calc(1.5em + .5rem + 2px);
    padding:.25rem .5rem;
    border:1px solid #ced4da;
    background-color:#fff;
    color:#495057;
    border-radius:.25rem
}
.table-edits input:focus,.table-edits select:focus{
    outline:0;
    border-color:#b9bfc4
}
.apex-charts{
    min-height:10px!important
}
.apex-charts text{
    font-family:var(--bs-font-sans-serif)!important;
    fill:#adb5bd
}
.apex-charts .apexcharts-canvas{
    margin:0 auto
}
.apexcharts-tooltip-text,.apexcharts-tooltip-title{
    font-family:var(--bs-font-sans-serif)!important
}
.apexcharts-legend-series{
    font-weight:500
}
.apexcharts-gridline{
    pointer-events:none;
    stroke:#f8f9fa
}
.apexcharts-legend-text{
    color:#74788d!important;
    font-family:var(--bs-font-sans-serif)!important;
    font-size:13px!important
}
.apexcharts-pie-label{
    fill:#fff!important
}
.apexcharts-xaxis text,.apexcharts-yaxis text{
    font-family:var(--bs-font-sans-serif)!important;
    fill:#adb5bd
}
.e-charts{
    height:350px
}
.flot-charts-height{
    height:320px
}
.flotTip{
    padding:8px 12px;
    background-color:rgba(52,58,64,.9);
    z-index:100;
    color:#f8f9fa;
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    border-radius:4px
}
.legendLabel{
    color:#adb5bd
}
.jqstooltip{
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    width:auto!important;
    height:auto!important;
    background-color:#343a40!important;
    -webkit-box-shadow:0 1rem 3rem rgba(0,0,0,.175);
    box-shadow:0 1rem 3rem rgba(0,0,0,.175);
    padding:5px 10px!important;
    border-radius:3px;
    border-color:#212529!important
}
.jqsfield{
    color:#eff2f7!important;
    font-size:12px!important;
    line-height:18px!important;
    font-family:var(--bs-font-sans-serif)!important;
    font-weight:500!important
}
.gmaps,.gmaps-panaroma{
    height:300px!important;
    background:#f8f9fa;
    border-radius:3px
}
.gmaps-overlay{
    display:block;
    text-align:center;
    color:#fff;
    font-size:16px;
    line-height:40px;
    background:#556ee6;
    border-radius:4px;
    padding:10px 20px
}
.gmaps-overlay_arrow{
    left:50%;
    margin-left:-16px;
    width:0;
    height:0;
    position:absolute
}
.gmaps-overlay_arrow.above{
    bottom:-15px;
    border-left:16px solid transparent;
    border-right:16px solid transparent;
    border-top:16px solid #556ee6
}
.gmaps-overlay_arrow.below{
    top:-15px;
    border-left:16px solid transparent;
    border-right:16px solid transparent;
    border-bottom:16px solid #556ee6
}
.jvectormap-label{
    border:none;
    background:#343a40;
    color:#f8f9fa;
    font-family:var(--bs-font-sans-serif);
    font-size:.8125rem;
    padding:5px 8px
}
.leaflet-map{
    height:300px
}
.leaflet-map.leaflet-container{
    z-index:99
}
.home-btn{
    position:absolute;
    top:15px;
    right:25px
}
.auth-logo .auth-logo-dark{
    display:block
}
.auth-logo .auth-logo-light{
    display:none
}
.auth-body-bg{
    background-color:#fff
}
.auth-pass-inputgroup input[type=input]+.btn .mdi-eye-outline:before{
    content:"\F06D1"
}
.auth-full-bg{
    background-color:rgba(85,110,230,.25);
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
}
@media (min-width:1200px){
    .auth-full-bg{
        height:100vh
    }
}
.auth-full-bg::before{
    content:"";
    position:absolute;
    width:300px;
    height:300px;
    border-radius:50%
}
.auth-full-bg .bg-overlay{
  
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center
}
.auth-full-page-content{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
}
@media (min-width:1200px){
    .auth-full-page-content{
        min-height:100vh
    }
}
.auth-review-carousel.owl-theme .owl-dots .owl-dot span{
    background-color:rgba(85,110,230,.25)
}
.auth-review-carousel.owl-theme .owl-dots .owl-dot.active span,.auth-review-carousel.owl-theme .owl-dots .owl-dot:hover span{
    background-color:#556ee6
}
.search-box .form-control{
    border-radius:30px;
    padding-left:40px
}
.search-box .search-icon{
    font-size:16px;
    position:absolute;
    left:13px;
    top:0;
    line-height:38px
}
.product-list li a{
    display:block;
    padding:4px 0;
    color:#495057
}
.product-view-nav.nav-pills .nav-item{
    margin-left:4px
}
.product-view-nav.nav-pills .nav-link{
    width:36px;
    height:36px;
    font-size:16px;
    padding:0;
    line-height:36px;
    text-align:center;
    border-radius:50%
}
.product-ribbon{
    position:absolute;
    right:0;
    top:0
}
.product-detai-imgs .nav .nav-link{
    margin:7px 0
}
.product-detai-imgs .nav .nav-link.active{
    background-color:#f6f6f6
}
.product-color a{
    display:inline-block;
    text-align:center;
    color:#495057
}
.product-color a .product-color-item{
    margin:7px
}
.product-color a.active,.product-color a:hover{
    color:#556ee6
}
.product-color a.active .product-color-item,.product-color a:hover .product-color-item{
    border-color:#556ee6!important
}
.visa-card .visa-logo{
    line-height:.5
}
.visa-card .visa-pattern{
    position:absolute;
    font-size:385px;
    color:rgba(255,255,255,.05);
    line-height:.4;
    right:0;
    bottom:0
}
.checkout-tabs .nav-pills .nav-link{
    margin-bottom:24px;
    text-align:center;
    background-color:#fff;
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03)
}
.checkout-tabs .nav-pills .nav-link.active{
    background-color:#556ee6
}
.checkout-tabs .nav-pills .nav-link .check-nav-icon{
    font-size:36px
}
.email-leftbar{
    width:236px;
    float:left;
    padding:20px;
    border-radius:5px
}
.email-rightbar{
    margin-left:260px
}
.chat-user-box p.user-title{
    color:#343a40;
    font-weight:500
}
.chat-user-box p{
    font-size:12px
}
@media (max-width:767px){
    .email-leftbar{
        float:none;
        width:100%
    }
    .email-rightbar{
        margin:0
    }
}
.mail-list a{
    display:block;
    color:#74788d;
    line-height:24px;
    padding:8px 5px
}
.mail-list a.active{
    color:#f46a6a;
    font-weight:500
}
.message-list{
    display:block;
    padding-left:0
}
.message-list li{
    position:relative;
    display:block;
    height:50px;
    line-height:50px;
    cursor:default;
    -webkit-transition-duration:.3s;
    transition-duration:.3s
}
.message-list li a{
    color:#74788d
}
.message-list li:hover{
    background:#f6f6f6;
    -webkit-transition-duration:.05s;
    transition-duration:.05s
}
.message-list li .col-mail{
    float:left;
    position:relative
}
.message-list li .col-mail-1{
    width:320px
}
.message-list li .col-mail-1 .checkbox-wrapper-mail,.message-list li .col-mail-1 .dot,.message-list li .col-mail-1 .star-toggle{
    display:block;
    float:left
}
.message-list li .col-mail-1 .dot{
    border:4px solid transparent;
    border-radius:100px;
    margin:22px 26px 0;
    height:0;
    width:0;
    line-height:0;
    font-size:0
}
.message-list li .col-mail-1 .checkbox-wrapper-mail{
    margin:15px 10px 0 20px
}
.message-list li .col-mail-1 .star-toggle{
    margin-top:18px;
    margin-left:5px
}
.message-list li .col-mail-1 .title{
    position:absolute;
    top:0;
    left:110px;
    right:0;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    margin-bottom:0
}
.message-list li .col-mail-2{
    position:absolute;
    top:0;
    left:320px;
    right:0;
    bottom:0
}
.message-list li .col-mail-2 .date,.message-list li .col-mail-2 .subject{
    position:absolute;
    top:0
}
.message-list li .col-mail-2 .subject{
    left:0;
    right:200px;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap
}
.message-list li .col-mail-2 .date{
    right:0;
    width:170px;
    padding-left:80px
}
.message-list li.active,.message-list li.active:hover{
    -webkit-box-shadow:inset 3px 0 0 #556ee6;
    box-shadow:inset 3px 0 0 #556ee6
}
.message-list li.unread{
    background-color:#f6f6f6;
    font-weight:500;
    color:#292d32
}
.message-list li.unread a{
    color:#292d32;
    font-weight:500
}
.message-list .checkbox-wrapper-mail{
    cursor:pointer;
    height:20px;
    width:20px;
    position:relative;
    display:inline-block;
    -webkit-box-shadow:inset 0 0 0 1px #ced4da;
    box-shadow:inset 0 0 0 1px #ced4da;
    border-radius:1px
}
.message-list .checkbox-wrapper-mail input{
    opacity:0;
    cursor:pointer
}
.message-list .checkbox-wrapper-mail input:checked~label{
    opacity:1
}
.message-list .checkbox-wrapper-mail label{
    position:absolute;
    height:20px;
    width:20px;
    left:0;
    cursor:pointer;
    opacity:0;
    margin-bottom:0;
    -webkit-transition-duration:.05s;
    transition-duration:.05s;
    top:0
}
.message-list .checkbox-wrapper-mail label:before{
    content:"\F012C";
    font-family:"Material Design Icons";
    top:0;
    height:20px;
    color:#292d32;
    width:20px;
    position:absolute;
    margin-top:-16px;
    left:4px;
    font-size:13px
}
@media (max-width:575.98px){
    .message-list li .col-mail-1{
        width:200px
    }
}
@media (min-width:1200px){
    .filemanager-sidebar{
        min-width:230px;
        max-width:230px
    }
}
@media (min-width:1366px){
    .filemanager-sidebar{
        min-width:280px;
        max-width:280px
    }
}
.categories-list{
    padding:4px 0
}
.categories-list li a{
    display:block;
    padding:8px 12px;
    color:#495057;
    font-weight:500
}
.categories-list li.active a{
    color:#556ee6
}
.categories-list li ul{
    padding-left:16px
}
.categories-list li ul li a{
    padding:4px 12px;
    color:#74788d;
    font-size:13px;
    font-weight:400
}
@media (min-width:992px){
    .chat-leftsidebar{
        min-width:260px
    }
}
@media (min-width:1200px){
    .chat-leftsidebar{
        min-width:380px
    }
}
.chat-leftsidebar .chat-leftsidebar-nav .nav{
    background-color:#fff
}
.chat-leftsidebar .chat-leftsidebar-nav .tab-content{
    min-height:488px
}
.chat-noti-dropdown.active:before{
    content:"";
    position:absolute;
    width:8px;
    height:8px;
    background-color:#f46a6a;
    border-radius:50%;
    right:0
}
.chat-noti-dropdown .btn{
    padding:6px;
    -webkit-box-shadow:none;
    box-shadow:none;
    font-size:20px
}
.chat-search-box .form-control{
    border:0
}
.chat-list{
    margin:0
}
.chat-list li.active a{
    background-color:#fff;
    border-color:transparent;
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03)
}
.chat-list li a{
    display:block;
    padding:14px 16px;
    color:#74788d;
    -webkit-transition:all .4s;
    transition:all .4s;
    border-top:1px solid #eff2f7;
    border-radius:4px
}
.chat-list li a:hover{
    background-color:#fff;
    border-color:transparent;
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03)
}
.user-chat-nav .dropdown .nav-btn{
    height:40px;
    width:40px;
    line-height:40px;
    -webkit-box-shadow:none;
    box-shadow:none;
    padding:0;
    font-size:16px;
    background-color:#eff2f7;
    border-radius:50%
}
.user-chat-nav .dropdown .dropdown-menu{
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    border:1px solid #eff2f7
}
.chat-conversation li{
    clear:both
}
.chat-conversation .chat-day-title{
    position:relative;
    text-align:center;
    margin-bottom:24px
}
.chat-conversation .chat-day-title .title{
    background-color:#fff;
    position:relative;
    z-index:1;
    padding:6px 24px
}
.chat-conversation .chat-day-title:before{
    content:"";
    position:absolute;
    width:100%;
    height:1px;
    left:0;
    right:0;
    background-color:#eff2f7;
    top:10px
}
.chat-conversation .chat-day-title .badge{
    font-size:12px
}
.chat-conversation .conversation-list{
    margin-bottom:24px;
    display:inline-block;
    position:relative
}
.chat-conversation .conversation-list .ctext-wrap{
    padding:12px 24px;
    background-color:rgba(85,110,230,.1);
    border-radius:8px 8px 8px 0;
    overflow:hidden
}
.chat-conversation .conversation-list .ctext-wrap .conversation-name{
    font-weight:600;
    color:#556ee6;
    margin-bottom:4px
}
.chat-conversation .conversation-list .dropdown{
    float:right
}
.chat-conversation .conversation-list .dropdown .dropdown-toggle{
    font-size:18px;
    padding:4px;
    color:#74788d
}
@media (max-width:575.98px){
    .chat-conversation .conversation-list .dropdown .dropdown-toggle{
        display:none
    }
}
.chat-conversation .conversation-list .dropdown .dropdown-menu{
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    border:1px solid #eff2f7
}
.chat-conversation .conversation-list .chat-time{
    font-size:12px
}
.chat-conversation .right .conversation-list{
    float:right
}
.chat-conversation .right .conversation-list .ctext-wrap{
    background-color:#eff2f7;
    text-align:right;
    border-radius:8px 8px 0 8px
}
.chat-conversation .right .conversation-list .dropdown{
    float:left
}
.chat-conversation .right .conversation-list.last-chat .conversation-list:before{
    right:0;
    left:auto
}
.chat-conversation .last-chat .conversation-list:before{
    content:"\F0009";
    font-family:"Material Design Icons";
    position:absolute;
    color:#556ee6;
    right:0;
    bottom:0;
    font-size:16px
}
@media (max-width:575.98px){
    .chat-conversation .last-chat .conversation-list:before{
        display:none
    }
}
.chat-input-section{
    border-top:1px solid #eff2f7
}
.chat-input{
    border-radius:30px;
    background-color:#eff2f7!important;
    border-color:#eff2f7!important;
    padding-right:120px
}
.chat-input-links{
    position:absolute;
    right:16px;
    top:50%;
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%)
}
.chat-input-links li a{
    font-size:16px;
    line-height:36px;
    padding:0 4px;
    display:inline-block
}
@media (max-width:575.98px){
    .chat-send{
        min-width:auto
    }
}
.project-list-table{
    border-collapse:separate;
    border-spacing:0 12px
}
.project-list-table tr{
    background-color:#fff
}
.contact-links a{
    color:#495057
}
.profile-user-wid{
    margin-top:-26px
}
@media (min-width:576px){
    .currency-value{
        position:relative
    }
    .currency-value:after{
        content:"\F04E1";
        font-family:"Material Design Icons";
        font-size:24px;
        position:absolute;
        width:45px;
        height:45px;
        line-height:45px;
        border-radius:50%;
        text-align:center;
        right:0;
        top:50%;
        -webkit-transform:translateY(-50%);
        transform:translateY(-50%);
        background-color:#556ee6;
        color:#fff;
        z-index:9;
        right:-34px
    }
}
.crypto-buy-sell-nav-content{
    border:2px solid #f6f6f6;
    border-top:0
}
.kyc-doc-verification .dropzone{
    min-height:180px
}
.kyc-doc-verification .dropzone .dz-message{
    margin:24px 0
}
.section{
    position:relative;
    padding-top:80px;
    padding-bottom:80px
}
.section.bg-white{
    background-color:#fff!important
}
.small-title{
    color:#74788d;
    margin-bottom:8px
}
.navigation{
    padding:0 16px;
    width:100%;
    z-index:999;
    margin-bottom:0;
    -webkit-transition:all .5s ease-in-out;
    transition:all .5s ease-in-out;
    border-bottom:1px solid rgba(255,255,255,.1)
}
@media (max-width:991.98px){
    .navigation{
        background-color:#fff
    }
}
.navigation .navbar-logo{
    line-height:70px;
    -webkit-transition:all .4s;
    transition:all .4s
}
.navigation .navbar-logo .logo-dark{
    display:none
}
@media (max-width:991.98px){
    .navigation .navbar-logo .logo-dark{
        display:block
    }
}
.navigation .navbar-logo .logo-light{
    display:block
}
@media (max-width:991.98px){
    .navigation .navbar-logo .logo-light{
        display:none
    }
}
.navigation .navbar-nav .nav-item .nav-link{
    color:rgba(255,255,255,.6);
    line-height:58px;
    padding:6px 16px;
    font-weight:500;
    -webkit-transition:all .4s;
    transition:all .4s
}
@media (max-width:991.98px){
    .navigation .navbar-nav .nav-item .nav-link{
        color:#555b6d
    }
}
.navigation .navbar-nav .nav-item .nav-link.active,.navigation .navbar-nav .nav-item .nav-link:hover{
    color:rgba(255,255,255,.9)
}
@media (max-width:991.98px){
    .navigation .navbar-nav .nav-item .nav-link.active,.navigation .navbar-nav .nav-item .nav-link:hover{
        color:#556ee6
    }
}
@media (max-width:991.98px){
    .navigation .navbar-nav .nav-item .nav-link{
        line-height:28px!important
    }
}
.navigation.nav-sticky{
    background-color:#fff;
    -webkit-box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03);
    box-shadow:0 .75rem 1.5rem rgba(18,38,63,.03)
}
.navigation.nav-sticky .navbar-logo{
    line-height:60px
}
.navigation.nav-sticky .navbar-logo .logo-dark{
    display:block
}
.navigation.nav-sticky .navbar-logo .logo-light{
    display:none
}
.navigation.nav-sticky .navbar-nav .nav-item .nav-link{
    line-height:48px;
    color:#555b6d
}
.navigation.nav-sticky .navbar-nav .nav-item .nav-link.active,.navigation.nav-sticky .navbar-nav .nav-item .nav-link:hover{
    color:#556ee6
}
.bg-overlay{
    position:absolute;
    height:100%;
    width:100%;
    right:0;
    bottom:0;
    left:0;
    top:0;
    opacity:.7;
    background-color:#000
}
.hero-section{
    padding-top:220px;
    padding-bottom:190px
}
.hero-section.bg-ico-hero{
    
    background-size:cover;
    background-position:top
}
@media (max-width:575.98px){
    .hero-section{
        padding-top:140px;
        padding-bottom:80px
    }
}
.hero-section .hero-title{
    font-size:42px
}
@media (max-width:575.98px){
    .hero-section .hero-title{
        font-size:26px
    }
}
.hero-section .ico-countdown{
    font-size:22px;
    margin-right:-12px;
    margin-left:-12px
}
@media (max-width:575.98px){
    .hero-section .ico-countdown{
        display:block
    }
}
.hero-section .ico-countdown .coming-box{
    margin-right:12px;
    margin-left:12px;
    border:1px solid #eff2f7;
    border-radius:4px;
    padding:8px;
    background-color:#fff
}
@media (max-width:575.98px){
    .hero-section .ico-countdown .coming-box{
        display:inline-block;
        width:40%;
        margin-bottom:24px
    }
}
.hero-section .ico-countdown .coming-box span{
    background-color:#eff2f7;
    font-size:12px;
    padding:4px;
    margin-top:8px
}
.hero-section .softcap-progress{
    overflow:visible
}
.hero-section .softcap-progress .progress-bar{
    overflow:visible
}
.hero-section .softcap-progress .progress-label{
    position:relative;
    text-align:right;
    color:#495057;
    bottom:20px;
    font-size:12px;
    font-weight:500
}
.currency-price{
    position:relative;
    bottom:40px
}
.client-images img{
    max-height:34px;
    width:auto!important;
    margin:12px auto;
    opacity:.7;
    -webkit-transition:all .4s;
    transition:all .4s
}
.features-number{
    opacity:.1
}
.team-box .team-social-links a{
    color:#495057;
    font-size:14px
}
.blog-box .blog-badge{
    position:absolute;
    top:12px;
    right:12px
}
.landing-footer{
    padding:80px 0 40px;
    background-color:#2a3042;
    color:rgba(255,255,255,.5)
}
.landing-footer .footer-list-title{
    color:rgba(255,255,255,.9)
}
.landing-footer .footer-list-menu li a{
    display:block;
    color:rgba(255,255,255,.5);
    margin-bottom:14px;
    -webkit-transition:all .4s;
    transition:all .4s
}
.landing-footer .footer-list-menu li a:hover{
    color:rgba(255,255,255,.8)
}
.landing-footer .blog-post .post{
    display:block;
    color:rgba(255,255,255,.5);
    padding:16px 0;
    border-bottom:1px solid rgba(255,255,255,.1)
}
.landing-footer .blog-post .post .post-title{
    color:rgba(255,255,255,.8);
    font-size:14px
}
.landing-footer .blog-post .post:first-of-type{
    padding-top:0
}
.landing-footer .blog-post .post:last-of-type{
    padding-bottom:0;
    border-bottom:0
}
.landing-footer .footer-border{
    border-color:rgba(255,255,255,.1)
}
.counter-number{
    font-size:32px;
    font-weight:600;
    text-align:center;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
}
.counter-number span{
    font-size:16px;
    font-weight:400;
    display:block;
    padding-top:5px
}
.coming-box{
    width:25%
}
.hori-timeline .events .event-list{
    text-align:center;
    display:block
}
.hori-timeline .events .event-list .event-down-icon{
    position:relative
}
.hori-timeline .events .event-list .event-down-icon::before{
    content:"";
    position:absolute;
    width:100%;
    top:16px;
    left:0;
    right:0;
    border-bottom:3px dashed #f6f6f6
}
.hori-timeline .events .event-list .event-down-icon .down-arrow-icon{
    position:relative;
    background-color:#fff;
    padding:4px
}
.hori-timeline .events .event-list:hover .down-arrow-icon{
    -webkit-animation:fade-down 1.5s infinite linear;
    animation:fade-down 1.5s infinite linear
}
.hori-timeline .events .event-list.active .down-arrow-icon{
    -webkit-animation:fade-down 1.5s infinite linear;
    animation:fade-down 1.5s infinite linear
}
.hori-timeline .events .event-list.active .down-arrow-icon:before{
    content:"\ec4c"
}
.verti-timeline{
    border-left:3px dashed #f6f6f6;
    margin:0 10px
}
.verti-timeline .event-list{
    position:relative;
    padding:0 0 40px 30px
}
.verti-timeline .event-list .event-timeline-dot{
    position:absolute;
    left:-9px;
    top:0;
    z-index:9;
    font-size:16px
}
.verti-timeline .event-list .event-content{
    position:relative;
    border:2px solid #eff2f7;
    border-radius:7px
}
.verti-timeline .event-list.active .event-timeline-dot{
    color:#556ee6
}
.verti-timeline .event-list:last-child{
    padding-bottom:0
}
.plan-box .plan-btn{
    position:relative
}
.plan-box .plan-btn::before{
    content:"";
    position:absolute;
    width:100%;
    height:2px;
    background:#f6f6f6;
    left:0;
    right:0;
    top:12px
}
.blog-play-icon{
    position:absolute;
    top:50%;
    left:0;
    right:0;
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
    margin:0 auto
}

 